import { defineStore } from 'pinia'

const emptyPermissions = {
  "actions": [],
  "roles": [],
  "groups": [],
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: null,
    userData: null,
  }),
  getters: {
    email: (state) => state.userData?.email,
    partnerScope: (state) => state.userData?.scope?.partners,
    username: (state) => state.userData?.email?.split('@')[0] || '...',
    roles: (state) => state.userData?.permissions?.roles || [],
    permissions: (state) => state.userData?.permissions || emptyPermissions,
    isLoggedIn: (state) => !!state.accessToken,
  },
  actions: {
    hasPermission(permission) {
      const { actions, roles, groups } = this.permissions
      return actions.includes(permission) || roles.includes(permission) || groups.includes(permission)
    },
    async fetchUserData(accessToken) {
      this.accessToken = accessToken

      if (this.accessToken) {
        const headers = {
          Authorization: `Bearer ${this.accessToken}`,
        }
        const result = await fetch('/auth/api/v1/me', { headers })

        if (result.ok) {
          const response = await result.json()
          if (response.data) {
            this.userData = response.data
          }
        } else if (result.status == 401 || result.status == 403) {
          this.accessToken = null
        } else {
          console.error(result)
        }
      }
    },
    setUserData(accessToken, userData) {
      this.accessToken = accessToken
      this.userData = userData
    },
    async clearUserData() {
      this.accessToken = null
      this.userData = null
      await fetch('/auth/api/v1/logout', { method: 'POST' })
    },
  },
})
